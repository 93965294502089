export const envConfig = {
  isProd: false,
  apiHeadDev: "https://uat.aichanakya.in/chanakya",
  apiHeadProd: "https://api.aichanakya.in/chanakya",
  oneSignalAppId: "7c157eac-67bd-44b5-adf2-3f4f249eef26",
};

const getAuthConfig = () => {
  if (envConfig.isProd) {
    return {
      domain: "chanakya.uk.auth0.com",
      clientId: "urHTbsnjyzMFYxRgZbO30YSqmWOpNjfL",
    };
  } else {
    return {
      domain: "chanakya-stage.uk.auth0.com",
      clientId: "3rI7KKAtjErkD0gHLBO7buoiTJAcuHLx",
    };
  }
};

const getSentryDSN = () => {
  if (envConfig.isProd) {
    return "http://96dd76067fa6a8a458c2e8013164bb1a@103.179.120.85:9000/2";
  } else {
    return "https://162b697a0c183a7bd983dfd21bb24dcb@sentry.neurobridge.tech/5";
  }
};

// API HEAD
export const ApiHead = envConfig.isProd
  ? envConfig.apiHeadProd
  : envConfig.apiHeadDev;

// AUTH CONFIG
export const { domain: AUTH_DOMAIN, clientId: AUTH_CLIENT_ID } =
  getAuthConfig();

// SENTRY DSN
export const sentryDSN = getSentryDSN();

export const PAYU_BASE_URL = "https://secure.payu.in/_payment";

export const MERCHANT_KEY = "8UTS93";
export const MERCHANT_SALT =
  "MIIEwAIBADANBgkqhkiG9w0BAQEFAASCBKowggSmAgEAAoIBAQDjRKPLb4lqLmgQJDdEAJH2MYvAyQ4NUNt47VbXCyRqF7DnnrAcNTYQrQctZ8vqfEkiO6Fm6g1YwI4LyQXyawVXyaLgJ+eMDVQGWXWawBsghbpitv24Bz34usu8CDT/WXSAzyd7g540esFUF0I0E8DbYHYSIVi8BdV218CCzTLT/G3M/lz43qfNLLzs9wu/B9dQBFH4mUpFvW70ZU/dlF0NAo9upud7VLoqXC+DE+G1z5WV09xXkiuP5TGYJ2tRwUHKjDNgfhmKQNE+l7WhGDNskndBXpGvMOKLLloM+Ln49xFMb5Q4uFXCOW5k1o1yKbcZAkt0DG1eE4pj3L0KgHMBAgMBAAECggEBAMeelrAtu+lD8aub498bERnJ0xL2hh00FFJXRZuE8est+/i17cIZCvACyvca4IWJvUMQVWhr1GVMXHgFje+sCPle5m01H7BoVJFvKllOu4UJcPkb+icWVVyP1jvGiaZwEGDDk85tgRxfZkhSVZggcZk/VPX/zEz6YtEMGM/LJQYEXzsLrDQWCeYIn4EqkfC7wTFRgTE0ITJ859CzS2d8r6fWHHoJYB83Rr5beuIArXgZXY8BQbaqgag165wkfGMlxiLLFIpl+O1VKSdY5s/NcLaEUhZpE6whJJUyMVv/Gw1X0wm7Fj63TAuej/fWpi1c/OpqRT2sWQAOx4jXUXrEgQECgYEA8/7GjWQoQJ3c12PeCcGp96TjH+dWqHwzu10HlKK5WlHz5aTx3LrzqeT/gxvqyUbR6iN7OSGF6h+8rFxaaUZp7WN/QTyQDnzSK6ftnSLfMqjRV35FlQNqy6WeulAYTGT1IUReTitBsaYlU+hXoBR/fmzUG+XTz/l4S9QMAAjyhokCgYEA7nMt5+GxYLWW2YUjqtPEyqw9BicCnepZW935Zsqbb9z9mi+34O1aJxrIhaJmRhgdeAj85aKczocN8dNcnYnmRorfOzp1C819WOFsCdT1z+GHRZrPOZe0TCDkdzR4J10G70J6DOOLwxEyqqeWL6WyJSSdZ0nVZO3iYk0lz/Ek6rkCgYEAnTRM1zJt4NwVOs2EdMLLcNxS3e5eXiXP6usQhEqno7LjstUoY/AsKA18NwwYpOA1RWbg8LlRn7wdAuxp8CRHAYU2q2CK7FqtyyTiKnkaV3h/kKCx0kWmXFl0QFR0o1v0uB9lp3WlWQFUUZCETIaIcntJ2kOkyf3Q7QJNTQR2xgkCgYEAygEJgsfQXP15Lg18idude5UunWeu79j4mrsR7eL2i23L6jNCs2pgVYB7H7rUqc0EKgxzMpbwAIapaZSFgXCxhFFhi+uZ/MW4yvMtc6bWF6YX0v+zTjcWpue2pMXuhGpq1Yabnw+l9zs6kRgoQ3U71fuO3VHPVhGxF1YEEoVXoQECgYEAohq17q8+iysjbs9aGjHWRTUJuZDJD0uRJ6EoiExjgDBY8p91e7mX82dfPmUUXBIYYWcAD6ez1lwPgeJ8jWfin1Z/aL3QOnzlEV8brdVuysDBuWm9ixTM0ek4EiZfPUfh9K8n+HjbwWIbqk2+pi22EfWkMzH7piCShNuK4HktNa0=";

export const streamingChatURL: string = `${ApiHead}/chat/`;
export const temporaryChatURL: string = `${ApiHead}/temporary/chat/`;
export const googleSearchEngineURL: string = `${ApiHead.replace(
  "/chanakya",
  ""
)}/chanakya-pro/chat/`;
export const chatVisionUrl: string = `${ApiHead.replace(
  "/chanakya",
  "/chanakya-pro"
)}/chat-vision/`;

export const userSubscriptionDetail: string = `${ApiHead.replace(
  "/chanakya",
  ""
)}/chanakya-pro/user`;
